<template>
    <div class="build-status-icon" style="margin-top: 3px">
        <div class="d-flex" v-if="build.result == 'CANCELLED'">
            <b-icon icon="nut-fill" variant="secondary" font-scale="4"></b-icon>
            <p class="font-weight-lighter m-auto pl-2" v-if="detail">
                CANCELLED
            </p>
        </div>
        <div class="d-flex" v-else-if="!build.result">
            <b-icon
                icon="hourglass-split"
                variant="secondary"
                font-scale="2"
                tooltip="Waiting in queue"
            ></b-icon>
            <p
                class="font-weight-lighter text-secondary m-auto pl-2"
                v-if="detail"
            >
                IN QUEUE
            </p>
        </div>
        <div class="d-flex" v-else-if="build.result == 'INPROGRESS'">
            <b-icon
                icon="nut-fill"
                variant="warning"
                animation="spin"
                font-scale="4"
            ></b-icon>
            <p
                class="font-weight-lighter text-warning m-auto pl-2"
                v-if="detail"
            >
                IN PROGRESS
            </p>
        </div>
        <div
            class="d-flex"
            v-else-if="build.complete && build.result == 'SUCCESS'"
        >
            <b-icon icon="nut-fill" variant="success" font-scale="4"></b-icon>
            <p
                class="font-weight-lighter text-success m-auto pl-2"
                v-if="detail"
            >
                SUCCESS
            </p>
        </div>
        <div class="d-flex" v-else>
            <b-icon icon="nut-fill" variant="danger" font-scale="4"></b-icon>
            <p
                class="font-weight-lighter text-danger m-auto pl-2"
                v-if="detail"
            >
                FAILURE
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuildStatusIcon',
    props: {
        build: {
            type: Object,
            default: null,
        },
        detail: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.build-status-icon svg {
    height: 2rem;
    width: 2rem;
}
</style>
