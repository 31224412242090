import axios from 'axios'
import Vue from 'vue'
import store from './store.js'
import router from './route.js'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRocket, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Auth0Plugin } from './auth'
import createToast from '@/mixins/toast'

import 'bootstrap/dist/js/bootstrap.min.js'
import './css/app.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

library.add(faRocket, faSave)

Vue.component('font-awesome-icon', FontAwesomeIcon)

axios
    .get('config.json')
    .then((response) => response.data)
    .then((config) => {
        Vue.prototype.$config = config

        Vue.use(Auth0Plugin, {
            domain: config.AUTH0_DOMAIN,
            clientId: config.AUTH0_CLIENTID,
            audience: config.AUTH0_AUDIENCE,
            onRedirectCallback: (appState) => {
                router.push(
                    appState && appState.targetUrl
                        ? appState.targetUrl
                        : window.location.pathname
                )
            },
        })

        let app = new Vue({
            store,
            router,
            mixins: [createToast],
            render: (h) => h('router-view'),
        }).$mount('#app')

        Vue.config.errorHandler = (err) => {
            app.createToast(err, 'danger')
            throw err
        }
    })
