import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from './auth/authGuard'

import SignIn from './pages/SignIn.vue'
import SignUp from './pages/SignUp.vue'
import Projects from './pages/Projects.vue'
import Editor from './pages/Editor.vue'
import Docs from './pages/Docs.vue'
import Builds from './pages/Builds.vue'
import Preferences from './pages/Preferences.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/projects',
    },
    {
        path: '/e/:project_id',
        component: Editor,
        beforeEnter: authGuard,
    },
    {
        path: '/docs/:path*',
        component: Docs,
        beforeEnter: authGuard,
    },
    {
        path: '/projects',
        component: Projects,
        beforeEnter: authGuard,
    },
    {
        path: '/builds/:project_id',
        component: Builds,
        beforeEnter: authGuard,
    },
    {
        path: '/preferences',
        component: Preferences,
        beforeEnter: authGuard,
    },
    {
        path: '/signin',
        component: SignIn,
    },
    {
        path: '/signup',
        component: SignUp,
    },
]

const router = new VueRouter({
    routes,
})

export default router