<template>
    <Main>
        <iframe src="/docs/" title="" frameBorder="0"></iframe>
    </Main>
</template>

<script>
import Main from '../layouts/Main.vue'

export default {
    name: 'Docs',
    components: {
        Main,
    },
}
</script>

<style lang="scss" scoped>
iframe {
    height: 100%;
    width: 100%;
}
</style>
