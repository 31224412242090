<template>
    <Main>
        <b-tabs
            class="w-100 h-100 flex-1"
            pills
            vertical
            nav-class="split-layout-vertical-tabs border-right"
            no-fade
        >
            <b-tab>
                <template #title>
                    <b-icon-keyboard />
                    <span class="ml-3">Account</span>
                </template>
                <div class="mt-5">
                    <b-row>
                        <b-col class="col-3">
                            <h2>Account</h2>
                            <p>User account settings</p>
                        </b-col>
                        <b-col v-if="isSocial">
                            <b-alert show variant="primary">
                                <p class="mb-0">
                                    Your account credentials are managed by an
                                    external provider.
                                </p>
                            </b-alert></b-col
                        >
                        <b-col v-else>
                            <b-form @submit="changePassword">
                                <b-form-group label="Email" label-for="email">
                                    <b-form-input
                                        id="email"
                                        type="email"
                                        name="email"
                                        disabled
                                        :value="$auth.user.email"
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group
                                    label="New Password"
                                    label-for="password1"
                                    :invalid-feedback="
                                        formChangePassword.feedback
                                    "
                                    :state="formChangePassword.state"
                                >
                                    <b-form-input
                                        id="password1"
                                        type="password"
                                        v-model="formChangePassword.password1"
                                        :state="formChangePassword.state"
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <b-form-group
                                    label="Confirm Password"
                                    :invalid-feedback="
                                        formChangePassword.feedback
                                    "
                                    label-for="password2"
                                >
                                    <b-form-input
                                        id="password2"
                                        type="password"
                                        name="password2"
                                        v-model="formChangePassword.password2"
                                        :state="formChangePassword.state"
                                    >
                                    </b-form-input>
                                </b-form-group>
                                <b-button type="submit"
                                    >Change Password</b-button
                                >
                            </b-form>
                        </b-col>
                    </b-row>

                    <hr />
                    <b-row>
                        <b-col class="col-3">
                            <h2>Delete</h2>
                            <p>
                                Delete user account and all Projects, Files and
                                Builds
                            </p>
                        </b-col>
                        <b-col>
                            <b-button @click="showMsgBoxTwo" variant="danger"
                                >Delete Account</b-button
                            >
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
        </b-tabs>
    </Main>
</template>

<script>
import Main from '../layouts/Main.vue'
import { BIconKeyboard } from 'bootstrap-vue'
import { APIInstance } from '@/api'

export default {
    components: {
        Main,
        BIconKeyboard,
    },
    data: () => ({
        formChangePassword: {
            password1: '',
            password2: '',
            state: null,
            feedback: null,
        },
    }),
    methods: {
        showMsgBoxTwo() {
            const h = this.$createElement
            const a =
                '<p class="my-4">Are you sure you want to delete <b>' +
                this.$auth.user.email +
                '</b>?</p>' +
                '<p>This will delete all Projects, Files and Build results from our system.' +
                "  These won't be recoverable.</p>"
            const msgNode = h('div', { domProps: { innerHTML: a } })

            this.$bvModal
                .msgBoxConfirm([msgNode], {
                    title: 'Delete Account',
                    okVariant: 'danger',
                    okTitle: 'Delete Account',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        APIInstance()
                            .deleteAccount()
                            .then(() => {
                                this.$auth.logout({
                                    returnTo: window.location.origin,
                                })
                            })
                    }
                })
        },
        changePassword(btnEvent) {
            btnEvent.preventDefault()
            if (
                this.formChangePassword.password1 ===
                this.formChangePassword.password2
            ) {
                APIInstance()
                    .changePassword(null, {
                        password: this.formChangePassword.password1,
                    })
                    .then(() => {
                        this.$auth.logout({
                            returnTo: window.location.origin,
                        })
                    })
                    .catch((e) => {
                        this.formChangePassword.state = false
                        this.formChangePassword.feedback =
                            e.response.data.detail
                    })
            } else {
                this.formChangePassword.state = false
                this.formChangePassword.feedback = 'Passwords do not match'
            }
        },
    },
    computed: {
        isSocial() {
            let sub = this.$auth.user.sub
            return !sub.startsWith('auth0')
        },
    },
}
</script>

<style lang="scss" scoped></style>
