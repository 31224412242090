<template>
    <div class="d-flex flex-column flex-grow-1">
        <div id="editor" class="flex-grow-1"></div>
        <div class="px-3 p-1 bg-gray-200 text-right line-details">
            <span class="mb-0 pr-2">
                Last updated: {{ formatDate(activeFile.timestamp) }} ({{
                    formatToRelativeTime(activeFile.timestamp)
                }})
            </span>
            <span class="ml-2 mb-0 pr-2">
                Line {{ line.num }}, Col {{ line.col }} VHD
            </span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { EditorState, basicSetup } from '@codemirror/basic-setup'
import { EditorView, keymap } from '@codemirror/view'
import { indentWithTab } from '@codemirror/commands'
import { Compartment } from '@codemirror/state'
import { StreamLanguage } from '@codemirror/stream-parser'
import { vhdl } from '@codemirror/legacy-modes/mode/vhdl'
import { verilog } from '@codemirror/legacy-modes/mode/verilog'
import { formatDate, formatToRelativeTime } from '../../../util/util'

const CM_LANGS = {
    vhd: StreamLanguage.define(vhdl),
    sv: StreamLanguage.define(verilog),
}

export default {
    name: 'FileEditor',
    data() {
        return {
            line: {
                num: 1,
                col: 1,
            },
            fileStates: {},
        }
    },
    computed: {
        activeFile() {
            return this.$store.state.activeFile
        },
        activeProject() {
            return this.$store.state.openProject
        },
    },
    watch: {
        activeFile() {
            this.cm.setState(this.getStateForFile(this.activeFile))
        },
    },
    methods: {
        formatDate: formatDate,
        formatToRelativeTime: formatToRelativeTime,
        save(file) {
            file.content = this.getStateForFile(file).doc.toString()
            var project_id = this.activeProject.id
            this.$store
                .dispatch('updateFile', {project_id, file})
                .then(this.$store.dispatch('unMarkFileDirty', file.id))
        },
        discard(file) {
            Vue.delete(this.fileStates, file.id)
            this.$store.dispatch('unMarkFileDirty', file.id)
        },
        listenForHotkeys(e) {
            if ((e.ctrlKey || e.metaKey) && e.keyCode == 83) {
                e.preventDefault()
                this.save(this.activeFile)
            }
        },
        getStateForFile(file) {
            if (this.fileStates[file.id]) return this.fileStates[file.id]

            const languageConf = new Compartment()
            var extension = file.name.split('.').pop();
            let state = EditorState.create({
                doc: file.content,
                extensions: [
                    basicSetup,
                    keymap.of([indentWithTab]),
                    languageConf.of(CM_LANGS[extension]),
                    EditorView.lineWrapping,
                    EditorView.updateListener.of((v) => {
                        const range = v.state.doc.lineAt(
                            v.state.selection.main.head
                        )
                        const lineNumber = range.number
                        const to = v.state.selection.main.head - range.from
                        this.line.col = to + 1
                        this.line.num = lineNumber
                    }),
                    EditorView.updateListener.of((v) => {
                        if (v.docChanged) {
                            this.$store.dispatch('markFileDirty', file.id)
                        }
                        this.fileStates[file.id] = v.state
                    }),
                ],
            })
            // this breaks reactivity, but I think that's what we want
            this.fileStates[file.id] = state
            return state
        },
        setupCodemirror() {
            this.cm = new EditorView({
                state: this.getStateForFile(this.activeFile),
                parent: document.querySelector('#editor'),
            })
        },
    },
    mounted() {
        this.setupCodemirror()
        window.addEventListener('keydown', this.listenForHotkeys)
    },
    destroyed() {
        window.removeEventListener('keydown', this.listenForHotkeys)
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

#editor {
    overflow-y: auto;
    min-height: 0;
    height: 0;
}

.line-details {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;

    > span:not(:last-child) {
        border-right: 1px solid $gray-500;
    }
}
</style>
