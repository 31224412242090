import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { APIInstance } from '@/api'

Vue.use(Vuex)

const state = {
    local_projects: {},
    openProject: null,
    projectFiles: {},
    activeFile: null,
    openFiles: {},
    latestBuild: null,
    projectBuilds: [],
    dirtyFiles: [],
    projectTemplates: [],
    availableConfig: {},
}

const getters = {}

const actions = {
    availableConfiguration(context) {
        return APIInstance()
            .buildConfigs()
            .then((r) => {
                context.commit('SET_CONFIGURATION', r.data)
            })
    },

    newFile(context, { name, project }) {
        return APIInstance()
            .createFile({ project_id: project.id, name: name })
            .then((r) => {
                context.commit('ADD_FILE', r.data)
                return r.data
            })
    },

    updateFile(context, { project_id, file }) {
        return APIInstance()
            .updateFile(
                { project_id: project_id, name: file.name },
                file.content,
                { headers: { 'Content-Type': 'text/plain' } }
            )
            .then((r) => {
                file.timestamp = r.data
                context.commit('MODIFY_FILE', file)
            })
    },

    deleteFile(context, { project_id, file }) {
        return context.dispatch('closeFile', file).then(() =>
            APIInstance()
                .deleteFile({ project_id: project_id, name: file.name })
                .then(() => {
                    context.commit('REMOVE_FILE', file)
                })
        )
    },

    getFiles({ commit }) {
        return APIInstance()
            .getProjectFiles(this.state.openProject.id)
            .then((r) => {
                commit('SET_FILES', r.data)
            })
    },

    openFile(context, file) {
        if (state.openFiles[file.id]) return
        return APIInstance()
            .getFile({ project_id: this.state.openProject.id, name: file.name })
            .then((r) => {
                file.content = r.data
                context.commit('OPEN_FILE', file)
            })
    },

    closeFile(context, file) {
        if (state.openFiles[file.id]) context.commit('CLOSE_FILE', file)

        if (state.activeFile && state.activeFile.id == file.id)
            context.dispatch('setActiveFile', Object.values(state.openFiles)[0])
    },

    setActiveFile(context, file) {
        if (!file) {
            context.commit('ACTIVE_FILE', null)
            return
        }

        if (state.activeFile && state.activeFile.id == file.id) return

        if (!state.openFiles[file.id])
            return context
                .dispatch('openFile', file)
                .then(() =>
                    context.commit('ACTIVE_FILE', state.openFiles[file.id])
                )

        context.commit('ACTIVE_FILE', state.openFiles[file.id])
    },

    fetchProjects(context) {
        return APIInstance()
            .getProjects()
            .then((r) => {
                context.commit('SET_PROJECTS', r.data)
            })
    },

    newProject(context, name) {
        return APIInstance()
            .createProject(null, name)
            .then((r) => {
                context.commit('ADD_PROJECT', r.data)
                return r.data
            })
    },

    deleteProject(context, project) {
        return APIInstance()
            .deleteProject(project.id)
            .then(() => {
                context.commit('REMOVE_PROJECT', project)
            })
    },

    editProject(context, project) {
        return APIInstance()
            .updateProject(project.id, project)
            .then((r) => {
                context.commit('MODIFY_PROJECT', r.data)
                if (state.openProject.id == r.data.id) {
                    state.openProject = r.data
                }
            })
    },

    openProject(context, project_id) {
        if (state.openProject && project_id == state.openProject.id) return

        const proj = APIInstance().getProject(project_id)
        const files = APIInstance().getProjectFiles(project_id)

        return axios
            .all([proj, files])
            .then(
                axios.spread((p, f) => {
                    context.commit('OPEN_PROJECT', p.data)
                    context.commit('SET_FILES', f.data)
                })
            )
            .then(() => context.dispatch('getLatestBuild', project_id))
    },

    buildProject(context) {
        return APIInstance()
            .newProjectBuild(
                {
                    project_id: state.openProject.id,
                },
                // { comments: 'test' }
            )
            .then((r) => context.commit('LATEST_BUILD', r.data))
    },

    cancelBuild(context, request_id) {
        return APIInstance()
            .cancelBuild({
                request_id: request_id,
            })
            .then((r) => {
                return r.data
            })
    },

    getLatestBuild(context, project_id) {
        return APIInstance()
            .getLatestBuild(project_id)
            .then((r) => context.commit('LATEST_BUILD', r.data))
    },

    updateLatestBuild(context) {
        if (state.latestBuild == null) return
        return APIInstance()
            .buildDetails(state.latestBuild.id)
                        .then((r) => context.commit('LATEST_BUILD', r.data))
    },

    loadBuilds(context, project_id) {
        return APIInstance()
            .projectBuilds(project_id)
            .then((r) => context.commit('SET_PROJECT_BUILDS', r.data))
    },

    markFileDirty(context, file_id) {
        context.commit('MARK_DIRTY', file_id)
    },

    unMarkFileDirty(context, file_id) {
        context.commit('UNMARK_DIRTY', file_id)
    },

    projectTemplates(context) {
        axios
            .get('/examples/contents.json')
            .then((r) => context.commit('SET_PROJECT_TEMPLATES', r.data))
    },
}

const mutations = {
    SET_CONFIGURATION(state, configuration) {
        state.availableConfig = configuration
    },

    SET_PROJECTS(state, projects) {
        state.local_projects = projects.reduce(
            (a, p) => ({
                ...a,
                [p.id]: p,
            }),
            {}
        )
    },

    ADD_PROJECT(state, project) {
        Vue.set(state.local_projects, project.id, project)
    },

    REMOVE_PROJECT(state, project) {
        Vue.delete(state.local_projects, project.id)
    },

    MODIFY_PROJECT(state, project) {
        Object.assign(state.local_projects[project.id], project)
    },

    OPEN_PROJECT(state, project) {
        // TODO remember these per project
        state.activeFile = null
        state.openFiles = {}
        state.openProject = project
        state.latestBuild = null
        Vue.set(state.local_projects, project.id, project)
    },

    ADD_FILE(state, file) {
        Vue.set(state.projectFiles, file.id, file)
    },

    REMOVE_FILE(state, file) {
        Vue.delete(state.projectFiles, file.id)
    },

    MODIFY_FILE(state, file) {
        Object.assign(state.projectFiles[file.id], file)
    },

    SET_FILES(state, files) {
        state.projectFiles = files.reduce(
            (a, f) => ({
                ...a,
                [f.id]: f,
            }),
            {}
        )
    },

    OPEN_FILE(state, file) {
        state.projectFiles[file.id] = file
        state.openFiles[file.id] = file
    },

    CLOSE_FILE(state, file) {
        Vue.delete(state.openFiles, file.id)
    },

    ACTIVE_FILE(state, file) {
        if (!file) state.activeFile = null
        else state.activeFile = state.openFiles[file.id]
    },

    LATEST_BUILD(state, build) {
        state.latestBuild = build
    },

    SET_PROJECT_BUILDS(state, builds) {
        state.projectBuilds = builds
    },

    MARK_DIRTY(state, file_id) {
        if (!state.dirtyFiles.includes(file_id)) state.dirtyFiles.push(file_id)
    },

    UNMARK_DIRTY(state, file_id) {
        if (state.dirtyFiles.includes(file_id))
            state.dirtyFiles.splice(state.dirtyFiles.indexOf(file_id), 1)
    },

    SET_PROJECT_TEMPLATES(state, data) {
        state.projectTemplates = data
    },
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
})
