<template>
    <b-row class="mx-2 my-2" align-self="center">
        <div v-for="(step, index) in buildSteps" :key="step.id" class="">
            <b-button
                size="sm"
                :pressed="step.id == latestStep.id"
                class="mb-2 text-uppercase"
                @click="$emit('update:currentStepId', step.id)"
                :variant="resolveResult(step.result)"
            >
                <b-icon :icon="getBuildIcon(step.name)" aria-hidden="true"></b-icon>
                {{ step.name }}
            </b-button>
            <b-icon-dash
                v-if="index < buildSteps.length - 1"
                font-scale="2"
                variant="secondary"
                style="vertical-align: middle"
            ></b-icon-dash>
        </div>
    </b-row>
</template>

<script>
export default {
    name: 'BuildSteps',
    props: {
        buildSteps: null,
    },
    computed: {
        latestStep() {
            if (this.buildSteps && this.buildSteps.length)
                return this.buildSteps[this.buildSteps.length - 1]
            return null
        },
    },
    watch: {
        buildSteps: function () {},
        latestStep() {
            if (this.latestStep)
                this.$emit('update:currentStepId', this.latestStep.id)
        },
    },
    methods: {
        resolveResult: function (result) {
            if (result == 'SUCCESS') {
                return 'success'
            } else if (result == 'FAILURE') {
                return 'danger'
            } else {
                return 'warning'
            }
        },
        getBuildIcon: function (name) {
            if (name == 'ip') return 'code-square'
            else if (
                name == 'synthesis' ||
                name == 'synthesize' /*legacy support */
            )
                return 'gear-fill'
            else if (name == 'route') return 'arrow-left-right'
            else if (name == 'bitstream') return 'file-zip-fill'
            else if (name == 'reports') return 'file-ruled-fill' // legacy support
        },
    },
}
</script>
