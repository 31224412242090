<template>
    <div :id="'accordion-container-' + id" class="accordion-container">
        <div class="ac">
            <p :class="['ac-header', headerClass]">
                <button class="ac-trigger">
                    <b-icon-chevron-down v-if="active" />
                    <b-icon-chevron-up v-else />
                    <span class="ml-1">
                        {{ title }}
                    </span>
                </button>
                <slot name="header-right" :show="show" :hide="hide"></slot>
            </p>
            <div :class="['ac-panel', bodyClass]">
                <div :class="['ac-text', textClass]">
                    <slot :show="show" :hide="hide"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from 'accordion-js'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'Accordion',
    props: {
        title: String,
        headerClass: {
            type: String,
            required: false,
        },
        bodyClass: {
            type: String,
            required: false,
        },
        textClass: {
            type: String,
            required: false,
        },
        showOnStart: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        BIconChevronUp,
        BIconChevronDown,
    },
    data: () => ({
        active: false,
        id: 0,
        accordion: null,
    }),
    methods: {
        show() {
            if (this.active) return
            this.accordion?.open(0)
            this.active = true
        },
        hide() {
            if (!this.active) return
            this.accordion?.close(0)
            this.active = false
        },
    },
    created() {
        this.id = this._uid
    },
    mounted() {
        this.accordion = new Accordion(`#accordion-container-${this.id}`, {
            duration: 250,
            beforeOpen: () => {
                this.active = true
            },
            beforeClose: () => {
                this.active = false
            },
            openOnInit: this.showOnStart ? [0] : [],
        })
    },
}
</script>

<style></style>
