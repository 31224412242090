<template>
    <b-col class="menu-container border-right" id="sidebar">
        <b-col class="menu py-4">
            <b-button
                variant="transparent"
                to="/projects"
                v-b-tooltip.hover.right="'Projects'"
            >
                <component :is="'b-icon-archive'" />
            </b-button>
            <b-button
                variant="transparent"
                :to="/e/ + projectId"
                v-if="projectOpen"
                v-b-tooltip.hover.right="'Editor'"
            >
                <component :is="'b-icon-code-slash'" />
            </b-button>
            <b-button
                variant="transparent"
                :to="/builds/ + projectId"
                v-if="projectOpen"
                v-b-tooltip.hover.right="'Builds'"
            >
                <component :is="'b-icon-box'" />
            </b-button>
        </b-col>

        <b-col class="menu py-4 justify-content-end">
            <b-button
                variant="transparent"
                v-b-tooltip.hover.right="'Documentation'"
                to="/docs/intro"
            >
                <component :is="'b-icon-question-circle'" />
            </b-button>
            <b-button
                variant="transparent"
                v-b-tooltip.hover.right="'Preferences'"
                to="/preferences"
            >
                <component :is="'b-icon-gear'" />
            </b-button>
        </b-col>
    </b-col>
</template>

<script>
import {
    BIconCodeSlash,
    BIconBox,
    BIconGear,
    BIconQuestionCircle,
    BIconArchive,
} from 'bootstrap-vue'

export default {
    name: 'SideBar',
    components: {
        BIconCodeSlash,
        BIconBox,
        BIconGear,
        BIconQuestionCircle,
        BIconArchive,
    },
    computed: {
        projectOpen() {
            return Boolean(this.$store.state.openProject)
        },
        projectId() {
            let p = this.$store.state.openProject
            if (p) return p.id
            return ''
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.menu-container {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 5rem;
    background-color: var(--light);
    flex-direction: column;
    margin: 0;
}

.menu {
    text-align: center;
    cursor: pointer;
    padding: 0;
    list-style: none;
    display: flex;
    row-gap: 2rem;
    flex-direction: column;

    .btn {
        padding: 0;
        height: 3rem;
        width: 3rem;
        transform: scale(1);
        transition: transform 0.2s ease, background-color 0.2s ease;
        border-radius: 50%;
        color: var(--secondary);

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &.router-link-active {
            background-color: $gray-200;
            transform: scale(1.25);
            color: #000 !important;
        }
    }
}
</style>
