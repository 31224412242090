import { render, staticRenderFns } from "./Builds.vue?vue&type=template&id=4edcd390&scoped=true&"
import script from "./Builds.vue?vue&type=script&lang=js&"
export * from "./Builds.vue?vue&type=script&lang=js&"
import style0 from "./Builds.vue?vue&type=style&index=0&id=4edcd390&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edcd390",
  null
  
)

export default component.exports