<template>
    <Main>
        <template v-slot:nav-left>
            <div class="d-flex align-items-center h-100 pl-2 p-1">
                <span class="color-white">
                    Projects&nbsp;/&nbsp; {{ projectName }}
                </span>
            </div>
        </template>
        <b-overlay :show="loading" class="h-100">
            <b-tabs
                v-if="builds.length"
                class="w-100 h-100"
                pills
                vertical
                nav-class="split-layout-vertical-tabs border-right"
                no-fade
                lazy
                @input="selectedBuild"
            >
                <b-tab
                    v-for="b in builds"
                    v-bind:key="b.id"
                    v-bind:button-id="b.id"
                >
                    <template #title>
                        <build-cell
                            v-bind:projectName="projectName"
                            v-bind:build="b"
                        ></build-cell>
                    </template>
                    <div class="p-6">
                        <b-row class="flex-1 pb-6 border-bottom">
                            <b-col lg="8">
                                <h2>
                                    <span class="font-weight-bold">Build</span>
                                    <strong class="ml-3"
                                        >{{ activeBuildDetail.id.slice(-7) }}
                                    </strong>
                                </h2>
                            </b-col>
                            <!-- <b-col lg="4">
                                <div
                                    v-if="!activeBuildDetail.complete"
                                    class="d-flex align-items-start"
                                >
                                    <b-button
                                        class="text-white w-100 py-1 my-1"
                                        size="md"
                                        variant="danger"
                                        @click="
                                            cancelBuild(activeBuildDetail.id)
                                        "
                                        >Cancel</b-button
                                    >
                                </div>
                            </b-col> -->
                        </b-row>

                        <b-row
                            class="flex-1 mt-6"
                            style="row-gap: 2rem"
                            no-gutters
                        >
                            <b-col lg="2">
                                <h4 class="font-weight-bold mb-0">Details</h4>
                            </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">STATUS</p>
                                <div class="d-flex">
                                    <build-status-icon
                                        v-bind:build="activeBuildDetail"
                                        v-bind:detail="true"
                                    ></build-status-icon>
                                </div>
                            </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">QUEUED</p>
                                <span v-if="activeBuildDetail.timestamp"
                                    >{{
                                        formatDateTime(
                                            activeBuildDetail.timestamp
                                        )
                                    }}
                                </span>
                                <span v-else>-</span>
                            </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">DURATION</p>
                                <span
                                    v-if="
                                        activeBuildDetail.started_at &&
                                        activeBuildDetail.completed_at
                                    "
                                    >{{
                                        formatDateDiff(
                                            activeBuildDetail.started_at,
                                            activeBuildDetail.completed_at
                                        )
                                    }}
                                </span>
                                <span v-else>-</span>
                            </b-col>
                        </b-row>
                        <b-row
                            class="flex-1 mt-6 pb-6 border-bottom"
                            style="row-gap: 2rem"
                            no-gutters
                        >
                            <b-col lg="2"> </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">HARDWARE VERSION</p>
                                <div class="d-flex">
                                    <span
                                        >{{ activeBuildDetail.hw_ver || '-' }}
                                    </span>
                                </div>
                            </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">FIRMWARE VERSION</p>
                                <span
                                    >{{ activeBuildDetail.fw_ver || '-' }}
                                </span>
                            </b-col>
                            <b-col sm="6" lg="3">
                                <p class="spaced-text">NO. OF SLOTS</p>
                                <span
                                    >{{ activeBuildDetail.slots || '-' }}
                                </span>
                            </b-col>
                        </b-row>
                        <b-row
                            class="flex-1 mt-6 pb-6 border-bottom"
                            no-gutters
                            style="row-gap: 2rem"
                        >
                            <b-col lg="2">
                                <h4 class="font-weight-bold mb-0">Artifacts</h4>
                            </b-col>
                            <b-col lg="10" class="reports-table">
                                <b-table
                                    :items="activeBuildDetail.artifacts"
                                    responsive
                                    thead-class="d-none"
                                >
                                    <template #cell(name)="data">
                                        <b-link
                                            @click="
                                                downloadFile(
                                                    '/api/builds/' +
                                                        activeBuild +
                                                        '/artifacts/' +
                                                        data.item.id,
                                                    data.item.name
                                                )
                                            "
                                        >
                                            <b-icon-download />
                                            <span class="p-2">Download</span>
                                        </b-link>
                                    </template>
                                    <template #cell(id)="data">
                                        {{ data.item.name }}
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <b-row
                            class="flex-1 mt-6 pb-6 border-bottom"
                            no-gutters
                            style="row-gap: 2rem"
                        >
                            <b-col lg="2">
                                <h4 class="font-weight-bold mb-0">Logs</h4>
                            </b-col>
                            <b-col lg="10" class="reports-table">
                                <BuildProgress
                                    :buildId="activeBuild"
                                    v-on:buildStatusChanged="loadBuildData"
                                />
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
            <div v-else class="empty-table">
                <div class="empty-table__icon">
                    <b-icon-box />
                </div>
                <p class="empty-table__content mt-4 mb-0 text-muted">
                    Hi there, it seems like you don’t have any builds for
                    <strong>{{ projectName }}</strong>
                    project yet.
                </p>
            </div>
        </b-overlay>
    </Main>
</template>

<script>
import { BIconBox } from 'bootstrap-vue'
import Main from '../layouts/Main.vue'
import BuildProgress from '../components/BuildProgress'
import BuildStatusIcon from '../components/BuildStatusIcon'
import BuildCell from '../components/BuildCell'
import downloadFile from '../mixins/download'
import { APIInstance } from '@/api'
import {
    formatDate,
    formatDateTime,
    formatDateDiff,
    composeBitstreamName,
} from '../../util/util'
import loading from '../mixins/loading'

export default {
    name: 'EditorBuilds',
    components: {
        Main,
        BIconBox,
        BuildProgress,
        BuildStatusIcon,
        BuildCell,
    },
    mixins: [downloadFile, loading],
    data() {
        return {
            filterBy: null,
            filters: [{ value: null, text: 'Filter By', disabled: true }],
            activeBuild: null,
            activeBuildDetail: this.buildDetailInitialState(),
        }
    },
    methods: {
        formatDate: formatDate,
        formatDateTime: formatDateTime,
        formatDateDiff: formatDateDiff,
        composeBitstreamName: composeBitstreamName,
        selectedBuild(tabIndex) {
            this.activeBuildDetail = this.buildDetailInitialState()
            this.activeBuild = this.builds[tabIndex].id
        },
        openProject() {
            this.showLoading()
            this.$store
                .dispatch('openProject', this.$route.params.project_id)
                .then(() => this.hideLoading())
        },
        loadBuildData() {
            APIInstance()
                .buildDetails(this.activeBuild)
                .then((r) => (this.activeBuildDetail = r.data))
        },
        cancelBuild(buildId) {
            this.$store.dispatch('cancelBuild', buildId)
            this.loadBuildData()
        },
        buildDetailInitialState() {
            return {
                id: '',
                timestamp: null,
                result: null,
                resources: [],
                artifacts: [],
            }
        },
    },
    mounted() {
        this.showLoading()
        this.$store
            .dispatch('loadBuilds', this.$route.params.project_id)
            .then(() => this.hideLoading())
    },
    computed: {
        builds() {
            return this.$store.state.projectBuilds
        },
        projectName() {
            if (this.$store.state.openProject)
                return this.$store.state.openProject.name
            return ''
        },
    },
    watch: {
        activeBuild() {
            this.loadBuildData()
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.openProject()
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.openProject()
        next()
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.reports-table::v-deep table {
    th {
        padding-top: 0;

        &:last-child {
            text-align: right;
        }
    }

    td:last-child {
        text-align: right;
    }
}

.nav-link .active {
    background-color: #e4e8ea;
}

.build-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
}

.build-empty__content {
    margin-top: 2rem;
    line-height: 1.75rem;
    color: $text-muted;
}

.empty-table {
    margin: 10rem auto;
}

.empty-table svg {
    height: 6rem;
    width: 6rem;
}

.empty-table__icon {
    height: 10rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    background-color: $gray-200;
    margin: 0 auto;
}

.empty-table__content {
    text-align: center;
    line-height: 1.75rem;
}
</style>
